/* Toastify
--------------------------------------------------------------------------------------------------------*/
.Toastify__toast {
  z-index: 9999;
  border-left: 4px solid #222629;
  border-radius: 4px;
  padding: 15px 45px;
  font-family: "Lato", sans-serif;
  background-color: #fff !important;
  position: relative;
}
.Toastify__toast .Toastify__close-button {
  color: #bababa;
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
  height: 100%;
}
.Toastify__toast .Toastify__toast-body {
  font-size: 14px;
  color: #666666;
}

.Toastify__toast::after {
  content: "";
  width: 24px;
  height: 24px;
  display: block;
  position: absolute;
  top: 19px;
  left: 10px;
  background-size: 24px 24px;
}
.Toastify__toast--success {
  border-left-color: #2bde3f;
}
.Toastify__toast--success::after {
  background-image: url(../images/success-icon.svg);
}
.Toastify__toast--warning {
  border-left-color: #ffc007;
}
.Toastify__toast--warning::after {
  background-image: url(../images/warning-icon.svg);
}
.Toastify__toast--error {
  border-left-color: #fb5151;
}
.Toastify__toast--error::after {
  background-image: url(../images/error-icon.svg);
}
.Toastify__toast--info {
  border-left-color: #1d72f3;
}
.Toastify__toast--info::after {
  background-image: url(../images/info-icon.svg);
}

.form-primary .form-control.is-invalid,
.form-secondary .form-control.error {
  border-color: #d73a1f;
}

.spinner-disable {
  display: none;
}

/* Loading 
--------------------------------------------------------------------------------------------------------*/
.loading {
  z-index: 9999;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.2);
}
.cssload-spin-box {
  position: absolute;
  margin: auto;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  box-shadow: 15px 15px #fff, -15px 15px rgb(212, 119, 111), -15px -15px #fff,
    15px -15px #a9a9a9;
  -o-box-shadow: 15px 15px #fff, -15px 15px rgb(212, 119, 111), -15px -15px #fff,
    15px -15px #a9a9a9;
  -ms-box-shadow: 15px 15px #fff, -15px 15px rgb(212, 119, 111),
    -15px -15px #fff, 15px -15px #a9a9a9;
  -webkit-box-shadow: 15px 15px #fff, -15px 15px rgb(212, 119, 111),
    -15px -15px #fff, 15px -15px #a9a9a9;
  -moz-box-shadow: 15px 15px #fff, -15px 15px rgb(212, 119, 111),
    -15px -15px #fff, 15px -15px #0a19ff;
  animation: cssload-spin ease infinite 4.6s;
  -o-animation: cssload-spin ease infinite 4.6s;
  -ms-animation: cssload-spin ease infinite 4.6s;
  -webkit-animation: cssload-spin ease infinite 4.6s;
  -moz-animation: cssload-spin ease infinite 4.6s;
}
@keyframes cssload-spin {
  0%,
  100% {
    box-shadow: 15px 15px #fff, -15px 15px #a9a9a9, -15px -15px #fff,
      15px -15px #fff;
  }
  25% {
    box-shadow: -15px 15px #fff, -15px -15px #fff, 15px -15px #fff,
      15px 15px #fff;
  }
  50% {
    box-shadow: -15px -15px #fff, 15px -15px #fff, 15px 15px #fff,
      -15px 15px #fff;
  }
  75% {
    box-shadow: 15px -15px #fff, 15px 15px #fff, -15px 15px #fff,
      -15px -15px #fff;
  }
}
@-ms-keyframes cssload-spin {
  0%,
  100% {
    box-shadow: 15px 15px #a9a9a9, -15px 15px #a9a9a9, -15px -15px #a9a9a9,
      15px -15px #a9a9a9;
  }
  25% {
    box-shadow: -15px 15px #a9a9a9, -15px -15px #fff, 15px -15px #a9a9a9,
      15px 15px #fff;
  }
  50% {
    box-shadow: -15px -15px #fff, 15px -15px #a9a9a9, 15px 15px #fff,
      -15px 15px #a9a9a9;
  }
  75% {
    box-shadow: 15px -15px #fff, 15px 15px #fff, -15px 15px #fff,
      -15px -15px #fff;
  }
}
@-webkit-keyframes cssload-spin {
  0%,
  100% {
    box-shadow: 15px 15px #fff, -15px 15px #a9a9a9, -15px -15px #fff,
      15px -15px #a9a9a9;
  }
  25% {
    box-shadow: -15px 15px #a9a9a9, -15px -15px #fff, 15px -15px #a9a9a9,
      15px 15px #fff;
  }
  50% {
    box-shadow: -15px -15px #fff, 15px -15px #a9a9a9, 15px 15px #fff,
      -15px 15px #a9a9a9;
  }
  75% {
    box-shadow: 15px -15px #fff, 15px 15px #fff, -15px 15px #fff,
      -15px -15px #fff;
  }
}
@-moz-keyframes cssload-spin {
  0%,
  100% {
    box-shadow: 15px 15px #fff, -15px 15px #a9a9a9, -15px -15px #fff,
      15px -15px #a9a9a9;
  }
  25% {
    box-shadow: -15px 15px #a9a9a9, -15px -15px #fff, 15px -15px #a9a9a9,
      15px 15px #fff;
  }
  50% {
    box-shadow: -15px -15px #fff, 15px -15px #a9a9a9, 15px 15px #fff,
      -15px 15px #a9a9a9;
  }
  75% {
    box-shadow: 15px -15px #fff, 15px 15px #fff, -15px 15px #fff,
      -15px -15px #fff;
  }
}

/* loading-battery
--------------------------------------------------------------------------------------------------------*/
.loading {
  z-index: 9999;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.2);
}
@keyframes load {
  0% {
    top: 4.5rem;
  }
  70% {
    top: 1.125rem;
  }
  90% {
    top: 0;
  }
  95% {
    top: 0;
  }
  100% {
    top: 4.5rem;
  }
}
@keyframes liquid-1 {
  0% {
    height: 0;
    opacity: 0;
    top: -0.5rem;
  }
  22% {
    height: 0.28125rem;
    top: 0.375rem;
    opacity: 1;
  }
  25% {
    top: -0.25rem;
  }
  35% {
    height: 1.125rem;
    top: -0.5rem;
  }
  55% {
    height: 0.28125rem;
    top: -0.125rem;
  }
  60% {
    height: 0.61875rem;
    opacity: 1;
    top: -0.275rem;
  }
  96% {
    height: 0.84375rem;
    opacity: 0;
    top: 0.5rem;
  }
  100% {
    height: 0;
    opacity: 0;
  }
}
@keyframes liquid-2 {
  0% {
    height: 0;
    opacity: 0;
    top: -0.5rem;
  }
  17.5% {
    height: 0.28125rem;
    top: 0.2rem;
    opacity: 1;
  }
  20% {
    top: -0.25rem;
  }
  25% {
    height: 1.40625rem;
    top: -0.625rem;
  }
  45% {
    height: 0.28125rem;
    top: -0.125rem;
  }
  60% {
    height: 1.40625rem;
    opacity: 1;
    top: -0.5rem;
  }
  96% {
    height: 0.84375rem;
    opacity: 0;
    top: 0.5rem;
  }
  100% {
    height: 0;
    opacity: 0;
  }
}
.battery {
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -2rem;
  margin-left: -1.12rem;
  width: 2.25rem;
  height: 4.5rem;
  box-shadow: 0 0 0 0.2rem #425c77;
  background: white;
  border-radius: 0.09rem;
}
.battery:before {
  content: "";
  position: absolute;
  left: 0.5625rem;
  right: 0.5625rem;
  top: -0.3375rem;
  height: 0.3375rem;
  width: 1.125rem;
  background: #425c77;
  border-radius: 0.18rem;
}
.battery:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-right: 2.25rem solid transparent;
  border-bottom: 4.05rem solid rgba(255, 255, 255, 0.325);
}
.liquid {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 2.25rem;
  background: #71fb85;
  animation: load 2.25s infinite;
}
.liquid:after,
.liquid:before {
  content: "";
  position: absolute;
  top: -0.5rem;
  height: 1.125rem;
  width: 1.4625rem;
  background: #71fb85;
  border-radius: 50%;
  opacity: 0;
}
.liquid:after {
  right: 0;
  animation: liquid-1 2.25s infinite;
}
.liquid:before {
  left: 0;
  animation: liquid-2 2.25s infinite;
}

@keyframes power {
  0% {
    color: #bcbcbc;
  }
  75% {
    color: #bcbcbc;
    text-shadow: none;
  }
  90% {
    color: #58fa70;
    text-shadow: 0 0 0.8em #71fb85;
  }
  100% {
    color: #bcbcbc;
    text-shadow: none;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.InputMask input[type="text"] {
  -webkit-text-security: disc;
}

.form-primary .icon {
  width: 50px;
  height: 40px;
}

.red-border {
  border: 1px solid #d73a1f;
}

.CookieConsent {
  justify-content: center !important;
  padding-right: 10px;
}
.CookieConsent > div:first-child {
  flex: 0 0 auto !important;
  max-width: 100%;
}

.pb-30p {
  padding-bottom: 30px !important;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.btn-primary.focus,
.btn-primary:focus,
.btn-primary:hover,
.btn-primary {
  background-color: #d73a1f !important;
  border: 0px !important;
}
.btn-outline-primary {
  color: #d73a1f;
  border-color: #d73a1f;
}
.btn-outline-primary:after {
  display: none;
}
.btn-outline-primary {
  border: 1px solid #d73a1f !important;
  background-color: #fff;
}
.btn-outline-primary span {
  color: #d73a1f;
}
.btn-outline-primary.focus,
.btn-outline-primary:focus,
.btn-outline-primary:hover,
.btn-outline-primary.dropdown-toggle.focus,
.btn-outline-primary.dropdown-toggle:focus,
.btn-outline-primary.dropdown-toggle:hover,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  background-color: #d73a1f !important;
}
.btn-outline-primary.focus span,
.btn-outline-primary:focus span,
.btn-outline-primary:hover span,
.btn-outline-primary:not(:disabled):not(.disabled).active span,
.btn-outline-primary:not(:disabled):not(.disabled):active span,
.show > .btn-outline-primary.dropdown-toggle span {
  color: #fff;
}
.btn-outline-primary.btn-icon .icon {
  overflow: hidden;
}
.btn-outline-primary.btn-icon .icon img {
  width: 35px;
  height: 16px;
  position: absolute;
  top: 0px;
  left: 0;
  display: block;
  transition: all 0.3s;
}
.btn-outline-primary.btn-icon.focus .icon img,
.btn-outline-primary.btn-icon:focus .icon img,
.btn-outline-primary.btn-icon:hover .icon img {
  margin-left: -19px;
}

.btn-secondary.focus,
.btn-secondary:focus,
.btn-secondary:hover,
.btn-secondary {
  background-color: #404040 !important;
  border: 0px !important;
}
.btn-link span {
  color: #333333;
}
.btn-link.focus,
.btn-link:focus,
.btn-link:hover,
.btn-link {
  background-color: transparent !important;
  border: 0px !important;
}
.actions-dropdown .dropdown-menu .dropdown-item:focus,
.actions-dropdown .dropdown-menu .dropdown-item:hover {
  background-color: #f2f2f2;
}
.withdraw-dropdown img {
  width: 18px;
  height: 18px;
  display: block;
  position: absolute;
  left: 10px;
  right: 10px;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.padding-0 {
  padding: 0 !important;
}

.form-group.is-invalid.css-2b097c-container .css-yk16xz-control {
  border-color: #dc3545 !important;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.min-vh-50 {
  min-height: 500px !important;
}

.m-15p {
  margin: 15px;
}
.mb-15p {
  margin-bottom: 15px;
}
.p-0 {
  padding: 0px;
}
.p-15p {
  padding: 15px;
}

.ta-l {
  text-align: left;
}

.tx-bold-black {
  font-weight: 600 !important;
  color: #000 !important;
}

.form-primary-info .form-group p {
  font-size: 14px;
  color: #131416;
  font-weight: 600;
  padding-bottom: 0;
}
.modal-footer {
  padding-bottom: 5px;
}
.modal-body {
  padding-bottom: 0px;
}

.authorise-session .info-text {
  font-size: 22px;
  color: #d73a1f;
}
.authorise-session .charge-auth-img {
  height: 135px;
  width: 170px;
}
.authorise-session .loader-div {
  padding-top: 25px;
  padding-bottom: 25px;
}
.authorise-session .sub-title {
  text-align: left;
  padding-left: 20px;
  font-size: 18px;
  color: #4f4f4f;
  padding-bottom: 0px;
  font-weight: 500;
}
.authorise-session .instruction-card-wrapper {
  padding: 10px 20px 10px 20px;
}
.authorise-session .instruction-card {
  border: 1px;
  border-radius: 6px;
  height: auto;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
.authorise-session .instruction-card-header {
  padding-top: 10px;
  padding-left: 10px;
}
.authorise-session .number-circle {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding: 0px;
  background: #fff;
  border: 2px solid #666;
  color: #666;
  text-align: center;
  font-size: 16px;
}
.authorise-session .instruction-body {
  display: flex;
  padding-bottom: 5px;
}
.authorise-session .instruction-text-div {
  font-size: 16px;
  padding-top: 20px;
  padding-left: 10px;
  text-align: left;
}
.authorise-session .instruction-img-div {
  text-align: left;
  padding-left: 10px;
  padding-top: 10px;
}
.authorise-session .instruction-img {
  height: 35px;
  width: 35px;
}
.authorise-session .charge-auth-img-div {
  padding-top: 15px;
}
.charging-progress .station-title-div {
  padding-left: 20px;
  display: flex;
  padding-top: 10px;
}
.charging-progress .marker-img-div {
  text-align: left;
}
.charging-progress .marker-img {
  height: 30px;
}
.charging-progress .station-name {
  padding-left: 15px;
  padding-top: 3px;
}
.charging-progress .station-title {
  font-size: 22px;
}
.charging-progress .charge-details-wrapper {
  padding: 10px 20px 15px 20px;
}
.charging-progress .charge-details-card {
  border: 1px;
  border-radius: 6px;
  height: auto;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  display: flex;
}
.charging-progress .detail-box {
  display: grid;
  padding: 15px;
}
.charging-progress .box-border-right {
  border-right: 1px solid #4f4f4f;
}
.charging-progress .box-border-bottom {
  border-bottom: 1px solid #4f4f4f;
}
.charging-progress .value-span {
  font-size: 24px;
  padding-right: 5px;
  font-weight: 600;
}
.charging-progress .info-span {
  font-size: 18px;
  font-weight: 600;
}
.charging-progress .info-label {
  font-size: 16px;
  color: #4f4f4f;
}
.charging-progress .stop-charge-div {
  margin-bottom: 20px;
}
.charging-progress .stop-charge-btn {
  display: inline-flex;
  align-items: center;
}
.charging-progress .stop-charge-span {
  font-size: 15px;
  min-width: 85px !important;
}
.charging-progress .stop-charge-img {
  height: 20px;
  padding-left: 5px;
}
.charging-progress .graph-div {
  padding: 20px;
}
.charging-progress .apexcharts-svg {
  overflow: visible;
}
.charging-progress .info-text {
  font-size: 22px;
  color: #d73a1f;
  height: 25vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.charging-progress .charging-complete {
  color: #666866;
  background-color: #f7fef7;
  border-radius: 8px;
  border: 1px solid #3cc278;
  padding: 10px;
  margin: 15px;
}
.charging-progress .session-complete-div {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.charging-progress .session-complete-span {
  padding-left: 10px;
  color: #3cc278;
  font-size: 20px;
}
.charging-progress .session-complete-message {
  display: flex;
  font-size: 18px;
  text-align: left;
  line-height: 20px;
}
.charging-progress .charging-error {
  color: #666866;
  background-color: #fef8f8;
  border-radius: 8px;
  border: 1px solid #e03605;
  padding: 10px;
  margin: 15px 15px 15px 15px;
}
.charging-progress .session-error-span {
  padding-left: 10px;
  color: #e03605;
  font-size: 20px;
}
.charging-progress .error-img {
  height: 25px;
}
.charging-progress .payment-success-cost {
  display: flex;
  margin: 5px 20px 0px 20px;
}
.charging-progress .cost-div {
  display: flex;
  align-items: baseline;
}
.charging-progress .cost-title {
  font-size: 18px;
  font-weight: 500;
  padding: 0 0 5px;
}
.charging-progress .cost-value {
  color: #4f4f4f;
  font-size: 16px;
  padding-top: 1px;
  text-align: left;
}

.charging-progress .sub-title {
  text-align: left;
  padding-left: 20px;
  font-size: 20px;
  color: #4f4f4f;
  padding-bottom: 0px;
  font-weight: 600;
}
.charging-progress .success-img {
  margin-top: 20px;
}
.charging-progress .error-color {
  color: #d73a1f;
}
.charging-progress .charity-label {
  font-size: 15px;
}
.charging-progress .settlement-failed {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 15px 15px 15px 15px;
}
.charging-progress .settlement-failed .settlement-failed-btn {
  width: 250px;
}
@media (max-width: 767px) {
  .authorise-session .instruction-text-div {
    padding-top: 10px;
  }
}
@media (max-width: 575px) {
  .authorise-session .instruction-text-div {
    font-size: 12px;
    padding-top: 10px;
    line-height: 15px;
  }
  .authorise-session .charge-auth-img {
    height: 90px;
    width: 120px;
  }
  .authorise-session .info-text {
    font-size: 16px;
  }
  .authorise-session .sub-title {
    font-size: 14px;
  }
  .authorise-session .instruction-img {
    height: 30px;
    width: 30px;
  }
  .charging-progress .marker-img {
    height: 25px;
  }
  .charging-progress .station-title {
    font-size: 18px;
  }
  .charging-progress .value-span {
    font-size: 18px;
  }
  .charging-progress .info-span {
    font-size: 14px;
  }
  .charging-progress .info-label {
    font-size: 11px;
    color: #4f4f4f;
  }
  .charging-progress .stop-charge-btn {
    height: 35px;
  }
  .charging-progress .info-text {
    font-size: 16px;
  }
  .charging-progress .session-complete-span {
    font-size: 18px;
  }
  .charging-progress .session-complete-message {
    font-size: 16px;
  }
  .charging-progress .session-error-span {
    font-size: 18px;
  }
  .charging-progress .error-img {
    height: 23px;
  }
  .charging-progress .cost-title {
    font-size: 14px;
  }
  .charging-progress .cost-value {
    font-size: 14px;
  }
  .charging-progress .charity-label {
    font-size: 14px;
  }
  .charging-progress .sub-title {
    font-size: 18px;
  }
}

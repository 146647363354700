.start-charge {
  padding: 70px 15px 70px 15px;
  position: relative;
}
.start-charge form {
  max-width: 500px;
  margin: auto;
}
.start-charge .form-primary .form-group .error.form-control {
  font-size: 16px;
  padding: 0px 15px;
}
.start-charge form a {
  color: #d73a1f;
}
.start-charge form a:hover {
  color: #a42d18;
}
.start-charge .back-button {
  position: absolute;
  top: 15px;
  right: 15px;
}
.start-charge .back-button-icon {
  left: 11px !important;
  height: 18px !important;
  width: 20px !important;
}
.charity-modal .modal-body {
  padding-bottom: 0px;
  padding: 30px 20px 0px 20px;
}
.donate-block {
  border-radius: 6px;
  overflow: hidden;
  background-color: #f5f5f5;
}
.donate-block .media-body {
  padding: 10px 10px 10px 0px;
}
.donate-block h6 {
  font-size: 14px;
  padding-bottom: 5px;
}
.donate-block > img {
  max-width: 130px;
  min-height: 130px;
}
.read-more {
  font-size: 13px;
  color: #d73a1f;
  font-weight: 600;
  margin-bottom: 5px;
  display: inline-block;
}
.padding-bottom-10 {
  padding-bottom: 10px !important;
}
.btn-green {
  color: #fff !important;
  background-color: #28a745 !important;
  border-color: #28a745 !important;
  border: 0 !important;
}
.btn-green:hover,
.btn-green:focus {
  color: #fff !important;
  background-color: #28a745 !important;
  border-color: #28a745 !important;
}
.charity-modal-header {
  border-bottom: 1px solid #ededed !important;
  padding: 15px !important;
}
.charity-modal-title {
  color: #212529 !important;
}
.charity-details{
  color: #666;
}
.note-label{
  color: #d73a1f;
}
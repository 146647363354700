@charset "utf-8";

/*
------------------------------------------------------------
Project Name : Instavolt Web Pay
;
------------------------------------------------------------
*/

/* Reset 
--------------------------------------------------------------------------------------------------------*/

/* 
http://meyerweb.com/eric/tools/css/reset/
v2.0 | 20110126
License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* General 
--------------------------------------------------------------------------------------------------------*/
* {
  outline: none !important;
}
body:not(.page-loaded) {
  opacity: 0;
}
.window-resizing *,
body:not(.page-loaded) * {
  transition: none !important;
}
.btn.icon-btn .icon,
.btn-icon span .icon,
a,
.container {
  transition: all 0.25s;
  -moz-transition: all 0.25s;
  -webkit-transition: all 0.25s;
}
html,
body {
  min-width: 320px;
  min-height: 100vh;
}
body {
  font: 14px/18px "Lato", sans-serif;
  font-weight: 400;
  -ms-overflow-style: scrollbar;
  background-color: #f3f3f3;
  opacity: 1 !important;
}
.wrapper {
  min-height: 100vh;
}
a {
  color: #000;
  text-decoration: none;
}
a:hover {
  color: #999;
  text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.2em;
  padding: 0 0 20px;
  font-weight: 600;
  color: #222629;
}
h1 span,
h2 span,
h3 span,
h4 span,
h5 span,
h6 span {
  display: block;
}
h1,
h2 {
  font-size: 26px;
}
h3 {
  font-size: 24px;
}
h4 {
  font-size: 22px;
}
h5 {
  font-size: 20px;
}
h6 {
  font-size: 18px;
}
p {
  font-size: 14px;
  font-weight: 400;
  padding: 0 0 20px;
}
strong,
.strong {
  font-weight: 700;
}
input[type="text"],
input[type="email"],
input[type="password"],
textarea {
  -webkit-appearance: none;
  appearance: none;
}
::-webkit-input-placeholder {
  color: #a4b1bd;
}
::-moz-placeholder {
  color: #a4b1bd;
}
:-ms-input-placeholder {
  color: #a4b1bd;
}
:-moz-placeholder {
  color: #a4b1bd;
}
::-moz-focus-inner {
  border: 0;
}
select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}
option:not(:checked) {
  color: black;
}

/* loading-peeek
--------------------------------------------------------------------------------------------------------*/
.loading {
  z-index: 9999;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.2);
}
.peeek-loading {
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
}
.peeek-loading ul {
  position: absolute;
  left: calc(50% - 0.7em);
  top: calc(50% - 4.2em);
  display: inline-block;
  text-indent: 2.8em;
}
.peeek-loading ul li:after,
.peeek-loading ul:after {
  width: 1.4em;
  height: 1.4em;
  background-color: #fff;
  border-radius: 100%;
}
.peeek-loading ul li:after,
.peeek-loading ul:after {
  content: "";
  display: block;
}
.peeek-loading ul:after {
  position: absolute;
  top: 2.8em;
}
.peeek-loading li {
  position: absolute;
  padding-bottom: 5.6em;
  top: 0;
  left: 0;
}
.peeek-loading li:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: 0.125s;
}
.peeek-loading li:nth-child(1):after {
  animation-delay: 0.125s;
}
.peeek-loading li:nth-child(2) {
  transform: rotate(36deg);
  animation-delay: 0.25s;
}
.peeek-loading li:nth-child(2):after {
  animation-delay: 0.25s;
}
.peeek-loading li:nth-child(3) {
  transform: rotate(72deg);
  animation-delay: 0.375s;
}
.peeek-loading li:nth-child(3):after {
  animation-delay: 0.375s;
}
.peeek-loading li:nth-child(4) {
  transform: rotate(108deg);
  animation-delay: 0.5s;
}
.peeek-loading li:nth-child(4):after {
  animation-delay: 0.5s;
}
.peeek-loading li:nth-child(5) {
  transform: rotate(144deg);
  animation-delay: 0.625s;
}
.peeek-loading li:nth-child(5):after {
  animation-delay: 0.625s;
}
.peeek-loading li:nth-child(6) {
  transform: rotate(180deg);
  animation-delay: 0.75s;
}
.peeek-loading li:nth-child(6):after {
  animation-delay: 0.75s;
}
.peeek-loading li:nth-child(7) {
  transform: rotate(216deg);
  animation-delay: 0.875s;
}
.peeek-loading li:nth-child(7):after {
  animation-delay: 0.875s;
}
.peeek-loading li:nth-child(8) {
  transform: rotate(252deg);
  animation-delay: 1s;
}
.peeek-loading li:nth-child(8):after {
  animation-delay: 1s;
}
.peeek-loading li:nth-child(9) {
  transform: rotate(288deg);
  animation-delay: 1.125s;
}
.peeek-loading li:nth-child(9):after {
  animation-delay: 1.125s;
}
.peeek-loading li:nth-child(10) {
  transform: rotate(324deg);
  animation-delay: 1.25s;
}
.peeek-loading li:nth-child(10):after {
  animation-delay: 1.25s;
}
.peeek-loading li {
  animation: dotAnimation 2.5s infinite;
}
@keyframes dotAnimation {
  0%,
  55%,
  100% {
    padding: 0 0 5.6em 0;
  }
  5%,
  50% {
    padding: 2.8em 0;
  }
}
.peeek-loading li:after {
  animation: dotAnimationTwo 2.5s infinite;
}
@keyframes dotAnimationTwo {
  0%,
  55%,
  100% {
    opacity: 1;
    transform: scale(1);
  }
  5%,
  50% {
    opacity: 0.5;
    transform: scale(0.5);
  }
}

/* loading-battery
--------------------------------------------------------------------------------------------------------*/
.loading {
  z-index: 9999;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.2);
}
@keyframes load {
  0% {
    top: 4.5rem;
  }
  70% {
    top: 1.125rem;
  }
  90% {
    top: 0;
  }
  95% {
    top: 0;
  }
  100% {
    top: 4.5rem;
  }
}
@keyframes liquid-1 {
  0% {
    height: 0;
    opacity: 0;
    top: -0.5rem;
  }
  22% {
    height: 0.28125rem;
    top: 0.375rem;
    opacity: 1;
  }
  25% {
    top: -0.25rem;
  }
  35% {
    height: 1.125rem;
    top: -0.5rem;
  }
  55% {
    height: 0.28125rem;
    top: -0.125rem;
  }
  60% {
    height: 0.61875rem;
    opacity: 1;
    top: -0.275rem;
  }
  96% {
    height: 0.84375rem;
    opacity: 0;
    top: 0.5rem;
  }
  100% {
    height: 0;
    opacity: 0;
  }
}
@keyframes liquid-2 {
  0% {
    height: 0;
    opacity: 0;
    top: -0.5rem;
  }
  17.5% {
    height: 0.28125rem;
    top: 0.2rem;
    opacity: 1;
  }
  20% {
    top: -0.25rem;
  }
  25% {
    height: 1.40625rem;
    top: -0.625rem;
  }
  45% {
    height: 0.28125rem;
    top: -0.125rem;
  }
  60% {
    height: 1.40625rem;
    opacity: 1;
    top: -0.5rem;
  }
  96% {
    height: 0.84375rem;
    opacity: 0;
    top: 0.5rem;
  }
  100% {
    height: 0;
    opacity: 0;
  }
}
.battery {
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -2rem;
  margin-left: -1.12rem;
  width: 2.25rem;
  height: 4.5rem;
  box-shadow: 0 0 0 0.2rem #425c77;
  background: white;
  border-radius: 0.09rem;
}
.battery:before {
  content: "";
  position: absolute;
  left: 0.5625rem;
  right: 0.5625rem;
  top: -0.3375rem;
  height: 0.3375rem;
  width: 1.125rem;
  background: #425c77;
  border-radius: 0.18rem;
}
.battery:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-right: 2.25rem solid transparent;
  border-bottom: 4.05rem solid rgba(255, 255, 255, 0.325);
}
.liquid {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 2.25rem;
  background: #71fb85;
  animation: load 2.25s infinite;
}
.liquid:after,
.liquid:before {
  content: "";
  position: absolute;
  top: -0.5rem;
  height: 1.125rem;
  width: 1.4625rem;
  background: #71fb85;
  border-radius: 50%;
  opacity: 0;
}
.liquid:after {
  right: 0;
  animation: liquid-1 2.25s infinite;
}
.liquid:before {
  left: 0;
  animation: liquid-2 2.25s infinite;
}

@keyframes power {
  0% {
    color: #bcbcbc;
  }
  75% {
    color: #bcbcbc;
    text-shadow: none;
  }
  90% {
    color: #58fa70;
    text-shadow: 0 0 0.8em #71fb85;
  }
  100% {
    color: #bcbcbc;
    text-shadow: none;
  }
}

/* Bootstrap Form Custom
--------------------------------------------------------------------------------------------------------*/
.badge {
  font-size: 13px;
  font-weight: 400;
  line-height: 25px;
  height: 25px;
  padding: 0 10px;
}
.badge-danger {
  background-color: #ff7c7c;
}
.badge-success {
  background-color: #6bb54d;
}

.text-primary {
  color: #d73a1f !important;
}
.text-secondary {
  color: #21a598 !important;
}

.btn,
.btn-sm {
  overflow: hidden;
  position: relative;
  padding: 10px 20px;
  border: 1px solid #e0e0e0 !important;
  height: 38px;
  border-radius: 4px !important;
  box-shadow: none !important;
  outline: none !important;
}
.btn span {
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  padding: 0px;
  margin: 0px;
  text-transform: capitalize;
  position: relative;
  z-index: 2;
  display: block;
  text-shadow: none !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.btn-sm:before,
.btn:before {
  content: "";
  background-color: rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 0;
  left: 25%;
  height: 100%;
  width: 50%;
  opacity: 0;
  transition: all 0.4s;
}
.btn-sm:hover:before,
.btn:hover:before {
  width: 100%;
  left: 0;
  opacity: 1;
  border-radius: 4px;
}
.btn-sm {
  height: 36px;
}

.btn-icon,
.btn-icon span {
  position: relative;
  padding-left: 40px;
}
.btn-icon .icon,
.btn-icon span .icon {
  position: absolute;
  left: 13px;
  top: 50%;
  display: block;
  height: 16px;
  width: 16px;
  margin-top: -7px;
  z-index: 2;
}
.btn-sm.btn-icon {
  padding-left: 14px;
}
.btn-sm.btn-icon .icon {
  left: 8px;
}

.btn-primary.focus,
.btn-primary:focus,
.btn-primary:hover,
.btn-primary {
  background-color: #d73a1f !important;
  border: 0px !important;
}
.btn-outline-primary.focus,
.btn-outline-primary:focus,
.btn-outline-primary:hover,
.btn-outline-primary {
  background-color: transparent !important;
  border: 1px solid #d73a1f !important;
}
.btn-outline-primary.btn:before {
  background-color: #fff;
}

.btn-secondary.focus,
.btn-secondary:focus,
.btn-secondary:hover,
.btn-secondary {
  background-color: #404040 !important;
  border: 0px !important;
}
.btn-info.focus,
.btn-info:focus,
.btn-info:hover,
.btn-info {
  background-color: #007bff !important;
  border: 0px !important;
}

.btn-link span {
  color: #333333;
}
.btn-link.focus,
.btn-link:focus,
.btn-link:hover,
.btn-link {
  background-color: transparent !important;
  border: 0px !important;
}

.custom-control {
  font-size: 16px;
  color: #4f4f4f;
  padding-left: 28px;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(147, 12, 30, 0.13);
}
.custom-checkbox .custom-control-label {
  line-height: 21px;
}
.custom-checkbox .custom-control-label::after,
.custom-checkbox .custom-control-label::before {
  cursor: pointer;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  box-shadow: none !important;
  outline: none !important;
  background-size: 12px 12px;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after,
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #d73a1f;
  background-color: #d73a1f;
  border-radius: 4px;
}
.custom-checkbox
  .custom-control-input:not(:disabled):active
  ~ .custom-control-label::before {
  background-color: #d73a1f;
  border-color: #d73a1f;
  border-radius: 4px;
}

.custom-radio .custom-control-label {
  line-height: 20px;
}
.custom-radio .custom-control-label::after,
.custom-radio .custom-control-label::before {
  cursor: pointer;
  top: 0;
  width: 18px;
  height: 18px;
  border-radius: 18px;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #fff;
  border-color: #d73a1f;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #d73a1f;
  background-image: none;
  width: 10px;
  height: 10px;
  border-radius: 10px;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  background-color: #fff;
  border-color: #d73a1f;
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #d73a1f;
}
.custom-radio .custom-control-label::after {
  top: 4px;
  left: -20px;
}

.custom-select {
  background-image: url("../images/down-arrow.svg");
  background-size: 12px 8px;
}

.form-group {
  margin-bottom: 20px;
  position: relative;
}
.form-group label {
  font-size: 14px;
  color: #4f4f4f;
  padding-bottom: 5px;
}
.custom-select,
.form-control {
  font-size: 14px;
  height: 38px;
  border-radius: 4px;
  border-color: #dadada !important;
  outline: none !important;
  box-shadow: none !important;
}
.custom-select:focus,
.form-control:focus {
  border-color: #505052 !important;
}
.custom-select.is-invalid,
.custom-select:invalid,
.form-control.is-invalid,
.form-control:invalid {
  border-color: #dc3545 !important;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #f2f2f2;
}
textarea.form-control {
  min-height: 40px;
}

.alert {
  position: relative;
  padding-right: 50px;
  text-align: left;
}
.alert .close {
  width: 26px !important;
  height: 26px !important;
  border-radius: 26px !important;
  padding: 7px;
  margin: 0;
  display: inline-block;
  opacity: 1;
  position: absolute;
  right: 8px;
  top: 8px;
}
.alert .close img {
  width: 12px !important;
  height: 12px !important;
  display: block;
}
.alert .close:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

/* Form controle
--------------------------------------------------------------------------------------------------------*/
.border-top-2 {
  border-top: 2px solid #e7e7e7 !important;
}
.max-w-100 {
  max-width: 100% !important;
}
.text-primary {
  color: #d73a1f !important;
}
.text-secondary {
  color: #21a598 !important;
}
.btn,
.btn-sm {
  overflow: hidden;
  position: relative;
  padding: 7px 20px;
  border: 1px solid #e0e0e0 !important;
  height: 40px;
  border-radius: 4px !important;
  box-shadow: none !important;
  outline: none !important;
}
.btn-sm:after,
.btn:after {
  content: "";
  background-color: rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 0;
  left: 25%;
  height: 100%;
  width: 50%;
  opacity: 0;
  transition: all 0.4s;
}
.btn-sm:hover:after,
.btn:hover:after {
  width: 100%;
  left: 0;
  opacity: 1;
  border-radius: 4px;
}
.btn-sm {
  height: 36px;
}
.btn-icon {
  position: relative;
  padding-left: 40px;
}
.btn-icon .icon {
  position: absolute;
  left: 15px;
  top: 50%;
  display: block;
  height: 16px;
  width: 16px;
  margin-top: -8px;
  z-index: 2;
}
.btn-sm.btn-icon {
  padding-left: 14px;
}
.btn-sm.btn-icon .icon {
  left: 8px;
}
.btn-icon span {
  position: relative;
}
.btn-icon span .icon {
  position: absolute;
  left: auto;
  right: 10px;
  top: 50%;
  display: block;
  height: 18px;
  width: 18px;
  margin-top: -7px;
}
.btn-icon:hover span .icon {
  right: 0;
}

.btn.icon-btn {
  padding: 0px;
  position: relative;
  width: 40px;
}
.btn.icon-btn .icon {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 80px;
  height: 40px;
}
.btn.icon-btn:hover .icon {
  left: -40px;
}

.btn-primary.focus,
.btn-primary:focus,
.btn-primary:hover,
.btn-primary {
  background-color: #d73a1f !important;
  border: 0px !important;
}
.btn-outline-primary:after {
  display: none;
}
.btn-outline-primary {
  border: 1px solid #d73a1f !important;
  background-color: #fff;
}
.btn-outline-primary span {
  color: #d73a1f;
}
.btn-outline-primary.focus,
.btn-outline-primary:focus,
.btn-outline-primary:hover {
  background-color: #d73a1f !important;
}
.btn-outline-primary.focus span,
.btn-outline-primary:focus span,
.btn-outline-primary:hover span {
  color: #fff;
}
.btn-outline-primary.btn-icon .icon {
  overflow: hidden;
}
.btn-outline-primary.btn-icon .icon img {
  width: 35px;
  height: 16px;
  position: absolute;
  top: 0px;
  left: 0;
  display: block;
  transition: all 0.3s;
}
.btn-outline-primary.btn-icon.focus .icon img,
.btn-outline-primary.btn-icon:focus .icon img,
.btn-outline-primary.btn-icon:hover .icon img {
  margin-left: -19px;
}

.btn-secondary.focus,
.btn-secondary:focus,
.btn-secondary:hover,
.btn-secondary {
  background-color: #404040 !important;
  border: 0px !important;
}
.btn-link span {
  color: #333333;
}
.btn-link.focus,
.btn-link:focus,
.btn-link:hover,
.btn-link {
  background-color: transparent !important;
  border: 0px !important;
}

/*Form Secondary --------------*/
.form-secondary .form-group {
  margin-bottom: 20px;
  position: relative;
}
.form-secondary .form-control {
  font-size: 16px;
  color: #333333;
  font-weight: 400;
  height: 48px;
  background-color: transparent;
  padding: 10px 0 0 0;
  border: 0px;
  border-bottom: 1px solid #ddd;
  border-radius: 0px;
  position: relative;
  z-index: 2;
}
.form-secondary .form-group .float-label {
  position: absolute;
  top: 21px;
  color: #333333;
  font-size: 14px;
  font-weight: 400;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.form-secondary .form-group.error .float-label {
  color: #d73a1f;
}
.form-secondary .form-group.error .form-control {
  border-bottom-color: #d73a1f;
}
.form-secondary .form-group.value-exists .float-label {
  top: -3px;
  font-size: 13px;
  color: #b9b9b9;
}
.form-secondary
  .form-group
  .form-control:not(:-ms-placeholder-shown)
  ~ .float-label {
  top: -24px;
  font-size: 12px;
}
.form-secondary .form-group .form-control:focus ~ .float-label,
.form-group select ~ .float-label {
  top: -24px;
  font-size: 12px;
}

/*Form primary --------------*/
.form-primary .form-group {
  margin-bottom: 20px;
  position: relative;
}
.form-primary .form-group label {
  font-size: 14px;
  color: #666666;
  padding-bottom: 5px;
}
.form-primary .form-group label small {
  font-size: 12px;
}
.form-primary .custom-select,
.form-primary .form-group .form-control {
  font-size: 16px;
  color: #333333;
  font-weight: 400;
  height: 40px;
  border-color: #dadada;
  border-radius: 4px;
}
.form-primary .form-group .error {
  font-size: 12px;
  padding: 0 15px;
  /* position: absolute; */
  left: 0;
  max-width: 100%;
}
.form-primary .input-group {
  border-radius: 4px;
}

.form-primary .custom-select {
  width: 100%;
  background-image: none;
}
.form-primary .form-group-icon {
  position: relative;
  padding-right: 70px;
}
.form-primary .form-group-icon .icon {
  width: 50px;
  height: 40px;
}

.input-group .input-group-prepend.last-child {
  margin-right: 0px;
  margin-left: -1px;
}
.input-group .input-group-prepend.last-child .input-group-text {
  border-radius: 0px 4px 4px 0px;
}
.custom-select:focus,
.form-control:focus {
  border-color: #000 !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  outline: none !important;
}

.address-block {
  position: relative;
}
.address-block .form-control {
  padding-right: 40px;
}
.address-block .icon-btn {
  width: 38px;
  height: 38px;
  background-color: #f5f5f5;
  position: absolute;
  right: 1px;
  top: 1px;
  display: block;
  border-radius: 0 4px 4px 0;
}
.address-block .icon-btn .icon {
  width: 38px;
  height: 38px;
  display: block;
}

.password-block {
  position: relative;
}
.password-block .form-control {
  padding-right: 35px;
}
.password-block .password-icon {
  cursor: pointer;
  z-index: 2;
  position: absolute;
  right: 5px;
  bottom: 0px;
  width: 35px;
  height: 40px;
  background-size: 20px 20px;
}
.form-primary .custom-control label.custom-control-label,
.custom-control-label {
  font-size: 14px;
  color: #666666;
  font-weight: 400;
  line-height: 28px;
  vertical-align: middle;
  cursor: pointer;
  padding-left: 3px;
}
.custom-control-label::after,
.custom-control-label::before {
  width: 20px;
  height: 20px;
}
.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #d73a1f;
  border-color: #d73a1f !important;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  background-color: #d73a1f;
  border-color: #d73a1f;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(216, 216, 216, 0.25) !important;
  border-color: #d73a1f !important;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url(../images/check.svg);
  top: 0.3rem;
  left: -1.45rem;
}

/* login
--------------------------------------------------------------------------------------------------------*/
.search-page {
  min-height: 100vh;
  width: 100%;
  background-color: #fff;
}
.search-page .search-background-block {
  background: url("../images/login-background.jpg") no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 100vh;
}
.search-page .instavolt-logo {
  padding: 30px;
  display: inline-block;
}
.search-page .search-section {
  background-color: #fff;
  width: auto;
  max-width: 655px;
  width: 100%;
  position: relative;
}
.search-page .search-outer {
  height: 100vh;
  display: table;
  width: 100%;
  vertical-align: middle;
}
.search-page .search-contain {
  margin: 0 auto;
  padding: 30px 135px;
  display: table-cell;
  vertical-align: middle;
}
.search-page .search-contain .title-outer {
  text-align: center;
}
.search-page .search-contain .title {
  font-size: 30px;
  color: #333333;
  font-weight: 400;
  line-height: 1.2;
  padding-bottom: 15px;
  text-align: center;
}
.search-page .search-contain .title img {
  display: block;
  margin: 0 auto 25px;
}
.search-page .search-contain .title .icon {
  width: 116px;
  height: 116px;
  border-radius: 116px;
  background-color: #fbf5f4;
  display: block;
  margin: 0 auto 25px;
}
.search-page .search-contain .title .icon img {
  width: 116px;
  height: 116px;
  display: block;
}
.search-page .search-contain .title-outer p {
  font-size: 14px;
  color: #343a40;
}
.search-page .search-contain .forgot-password {
  font-size: 14px;
  color: #d73a1f;
  line-height: 2;
}
.search-page .search-contain .forgot-password:hover {
  color: #666666;
}

.search-page .search-contain .social-media a {
  font-size: 13px;
  color: #fff;
  border-radius: 4px;
  padding: 7px 20px 7px 20px;
}
.search-page .search-contain .social-media a img {
  width: 22px;
  height: 22px;
  margin-right: 8px;
  display: inline-block;
  vertical-align: middle;
}

.search-page .copyright{
  font-size: 12px;
  color: #9d9d9d;
  text-align: center;
  padding: 10px;
  min-height: 40px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 70%;
}

.copyright .privacy-policy-link{
  color: #d6310f; 
  text-decoration: none;
  cursor: pointer;
}

.copyright .privacy-policy-link:hover{
  text-decoration: underline;
  color: #666666;
}

.google-btn {
  background-color: #f4594e;
}
.ios-btn {
  background-color: #000;
}
.facebook-btn {
  background-color: #4a77a9;
}
.search-page .search-contain .social-media a:hover {
  opacity: 0.7;
  box-shadow: 0 3px 8px rgb(0 0 0 / 20%);
}
.search-page .search-contain .login-link {
  color: #666666;
  font-size: 14px;
}
.search-page .search-contain .login-link:hover {
  color: #d73a1f;
}
.search-page .search-contain input {
  font-size: 16px !important;
  width: inherit !important;
}
.search-page .css-1pndypt-Input {
  overflow: hidden;
}
.border-top-1 {
  border-top: 1px solid #dedede;
}

.search-contain .btn-back {
  position: absolute;
  left: 15px;
  top: 15px;
}

.min-vh-50 {
  min-height: 500px !important;
}

/* Success messg
--------------------------------------------------------------------------------------------------------*/
.mesg-box img {
  width: 72px;
  height: 72px;
  display: block;
  margin: 0 auto 10px;
}
.mesg-box.process-block img {
  width: auto;
  height: 150px;
}
.mesg-box.process-block h2 {
  color: #b4b4b4;
}
.mesg-box h2 {
  font-size: 34px;
  text-transform: capitalize;
  padding-bottom: 20px;
  text-align: center;
}
.mesg-box .text-success {
  color: #3ec834;
}
.mesg-box .text-error {
  color: #ff8400;
}
.mesg-box p {
  font-size: 20px;
  color: #666666;
}

.badge {
  font-size: 13px;
  font-weight: 400;
  line-height: 25px;
  height: 25px;
  padding: 0 10px;
}
/* Main Content
--------------------------------------------------------------------------------------------------------*/
.main-content {
  padding: 0px 0px 40px;
  margin-top: -225px;
}
.card {
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
  box-shadow: 1px 1.732px 15px 0px rgba(0, 0, 0, 0.15);
  text-align: center;
}
.card .card-body {
  padding: 0 15px 30px;
  max-width: 910px;
  width: 100%;
  margin: 0 auto;
  text-align: left;
}
/* Header
--------------------------------------------------------------------------------------------------------*/
.header {
  padding-top: 20px;
  height: 317px;
  background: url("../images/inner-page-bg.png") no-repeat;
  background-position: center bottom;
  background-size: cover;
}
.padding-left-20 {
  padding-left: 20px !important;
}
.transaction-details {
  text-align: center;
  padding: 10px;
  width: auto;
  margin: auto;
  display: table;
  background-color: #f4f4f4;
  border-radius: 12px;
  margin-bottom: 15px;
}
.transaction-details li {
  display: block;
  padding: 10px;
}
.transaction-details li span {
  font-size: 24px;
  color: #666666;
}
.transaction-details li strong {
  font-size: 24px;
  color: #333333;
}
.css-yk16xz-control {
  font-size: 16px !important;
}
.css-2613qy-menu {
  font-size: 16px !important;
}

/* Responsive Media Queries
--------------------------------------------------------------------------------------------------------*/
@media (max-width: 1199px) {
  .search-page .search-section {
    max-width: 455px;
  }
  .search-page .search-contain {
    padding: 50px;
  }
  .CookieConsent {
    padding-left: 10px;
  }
  .CookieConsent > div:first-child {
    margin-bottom: 0px !important;
  }
}

@media (max-width: 767px) {
  .search-page .search-background-block {
    min-height: 0;
    background-image: url("../images/md-login-background.jpg");
    text-align: center;
  }
  .search-page .search-outer {
    height: auto;
  }
  .search-page .search-section {
    max-width: 100%;
    height: auto;
  }
  .search-page .search-contain {
    padding: 50px;
  }
  .CookieConsent {
    padding-left: 10px;
    height: 135px;
  }
  .CookieConsent > div:first-child {
    margin-bottom: 0px !important;
  }
}

@media (max-width: 575px) {
  .search-page .search-contain {
    padding: 50px 15px;
  }
  .current-balance .card-title {
    font-size: 24px;
  }
  .search-page .search-contain .social-media a {
    padding: 7px 10px 7px 7px;
  }
  .CookieConsent {
    padding-left: 10px;
    height: 135px;
  }
  .CookieConsent > div:first-child {
    margin-bottom: 0px !important;
  }
}

/* Toastify
--------------------------------------------------------------------------------------------------------*/
.Toastify__toast {
  z-index: 9999;
  border-left: 4px solid #222629;
  border-radius: 4px;
  padding: 15px 40px;
  font-family: "Lato", sans-serif;
  background-color: #fff !important;
  position: relative;
}
.Toastify__toast-icon {
  display: none;
}
.Toastify__toast .Toastify__close-button {
  color: #bababa;
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
  height: 100%;
}
.Toastify__toast .Toastify__toast-body {
  font-size: 14px;
  color: #666666;
}

.Toastify__toast::after {
  content: "";
  width: 24px;
  height: 24px;
  display: block;
  position: absolute;
  top: 19px;
  left: 10px;
  background-size: 24px 24px;
}
.Toastify__toast--success {
  border-left-color: #2bde3f;
}
.Toastify__toast--success::after {
  background-image: url(../images/success-icon.svg);
}
.Toastify__toast--warning {
  border-left-color: #ffc007;
}
.Toastify__toast--warning::after {
  background-image: url(../images/warning-icon.svg);
}
.Toastify__toast--error {
  border-left-color: #fb5151;
}
.Toastify__toast--error::after {
  background-image: url(../images/error-icon.svg);
}
.Toastify__toast--info {
  border-left-color: #1d72f3;
}
.Toastify__toast--info::after {
  background-image: url(../images/info-icon.svg);
}

.confirmation-block > img {
  width: 26px;
  height: 26px;
  display: block;
}
.confirmation-block .media-body {
  font-size: 16px;
  color: #666666;
  line-height: 23px;
}

.modal .modal-content {
  border-radius: 6px;
}
.modal .btn-close {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  padding: 7px;
  margin: 0;
  display: inline-block;
  opacity: 1;
  background: url("../images/close.svg") no-repeat center center/14px 14px;
  border: none;
}
.modal .btn-close span {
  display: none;
}
.modal .btn-close:hover {
  background-color: #f2f2f2;
}
.modal .modal-header {
  padding: 10px 15px;
  background-color: transparent;
  border: none;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.modal .modal-header .modal-title {
  color: #4f4f4f;
  font-weight: 700;
  padding-bottom: 0px;
}
.modal .modal-header .modal-title p {
  font-size: 14px;
  color: #9e9e9e;
  padding-bottom: 0;
  font-weight: 400;
}
.modal .modal-footer {
  border: 0px;
}
.modal .modal-footer {
  justify-content: flex-start;
  padding-top: 0px;
}
.form-group {
  margin-bottom: 20px;
  position: relative;
}
.form-group label {
  font-size: 14px;
  color: #4f4f4f;
  padding-bottom: 5px;
}

/* Loading 
--------------------------------------------------------------------------------------------------------*/
.loading {
  z-index: 9999;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.2);
}
.cssload-spin-box {
  position: absolute;
  margin: auto;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  box-shadow: 15px 15px #fff, -15px 15px rgb(212, 119, 111), -15px -15px #fff,
    15px -15px #a9a9a9;
  -o-box-shadow: 15px 15px #fff, -15px 15px rgb(212, 119, 111), -15px -15px #fff,
    15px -15px #a9a9a9;
  -ms-box-shadow: 15px 15px #fff, -15px 15px rgb(212, 119, 111),
    -15px -15px #fff, 15px -15px #a9a9a9;
  -webkit-box-shadow: 15px 15px #fff, -15px 15px rgb(212, 119, 111),
    -15px -15px #fff, 15px -15px #a9a9a9;
  -moz-box-shadow: 15px 15px #fff, -15px 15px rgb(212, 119, 111),
    -15px -15px #fff, 15px -15px #0a19ff;
  animation: cssload-spin ease infinite 4.6s;
  -o-animation: cssload-spin ease infinite 4.6s;
  -ms-animation: cssload-spin ease infinite 4.6s;
  -webkit-animation: cssload-spin ease infinite 4.6s;
  -moz-animation: cssload-spin ease infinite 4.6s;
}
@keyframes cssload-spin {
  0%,
  100% {
    box-shadow: 15px 15px #fff, -15px 15px #a9a9a9, -15px -15px #fff,
      15px -15px #fff;
  }
  25% {
    box-shadow: -15px 15px #fff, -15px -15px #fff, 15px -15px #fff,
      15px 15px #fff;
  }
  50% {
    box-shadow: -15px -15px #fff, 15px -15px #fff, 15px 15px #fff,
      -15px 15px #fff;
  }
  75% {
    box-shadow: 15px -15px #fff, 15px 15px #fff, -15px 15px #fff,
      -15px -15px #fff;
  }
}
@-ms-keyframes cssload-spin {
  0%,
  100% {
    box-shadow: 15px 15px #a9a9a9, -15px 15px #a9a9a9, -15px -15px #a9a9a9,
      15px -15px #a9a9a9;
  }
  25% {
    box-shadow: -15px 15px #a9a9a9, -15px -15px #fff, 15px -15px #a9a9a9,
      15px 15px #fff;
  }
  50% {
    box-shadow: -15px -15px #fff, 15px -15px #a9a9a9, 15px 15px #fff,
      -15px 15px #a9a9a9;
  }
  75% {
    box-shadow: 15px -15px #fff, 15px 15px #fff, -15px 15px #fff,
      -15px -15px #fff;
  }
}
@-webkit-keyframes cssload-spin {
  0%,
  100% {
    box-shadow: 15px 15px #fff, -15px 15px #a9a9a9, -15px -15px #fff,
      15px -15px #a9a9a9;
  }
  25% {
    box-shadow: -15px 15px #a9a9a9, -15px -15px #fff, 15px -15px #a9a9a9,
      15px 15px #fff;
  }
  50% {
    box-shadow: -15px -15px #fff, 15px -15px #a9a9a9, 15px 15px #fff,
      -15px 15px #a9a9a9;
  }
  75% {
    box-shadow: 15px -15px #fff, 15px 15px #fff, -15px 15px #fff,
      -15px -15px #fff;
  }
}
@-moz-keyframes cssload-spin {
  0%,
  100% {
    box-shadow: 15px 15px #fff, -15px 15px #a9a9a9, -15px -15px #fff,
      15px -15px #a9a9a9;
  }
  25% {
    box-shadow: -15px 15px #a9a9a9, -15px -15px #fff, 15px -15px #a9a9a9,
      15px 15px #fff;
  }
  50% {
    box-shadow: -15px -15px #fff, 15px -15px #a9a9a9, 15px 15px #fff,
      -15px 15px #a9a9a9;
  }
  75% {
    box-shadow: 15px -15px #fff, 15px 15px #fff, -15px 15px #fff,
      -15px -15px #fff;
  }
}

/* loading-battery
--------------------------------------------------------------------------------------------------------*/
.loading {
  z-index: 9999;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.2);
}
@keyframes load {
  0% {
    top: 4.5rem;
  }
  70% {
    top: 1.125rem;
  }
  90% {
    top: 0;
  }
  95% {
    top: 0;
  }
  100% {
    top: 4.5rem;
  }
}
@keyframes liquid-1 {
  0% {
    height: 0;
    opacity: 0;
    top: -0.5rem;
  }
  22% {
    height: 0.28125rem;
    top: 0.375rem;
    opacity: 1;
  }
  25% {
    top: -0.25rem;
  }
  35% {
    height: 1.125rem;
    top: -0.5rem;
  }
  55% {
    height: 0.28125rem;
    top: -0.125rem;
  }
  60% {
    height: 0.61875rem;
    opacity: 1;
    top: -0.275rem;
  }
  96% {
    height: 0.84375rem;
    opacity: 0;
    top: 0.5rem;
  }
  100% {
    height: 0;
    opacity: 0;
  }
}
@keyframes liquid-2 {
  0% {
    height: 0;
    opacity: 0;
    top: -0.5rem;
  }
  17.5% {
    height: 0.28125rem;
    top: 0.2rem;
    opacity: 1;
  }
  20% {
    top: -0.25rem;
  }
  25% {
    height: 1.40625rem;
    top: -0.625rem;
  }
  45% {
    height: 0.28125rem;
    top: -0.125rem;
  }
  60% {
    height: 1.40625rem;
    opacity: 1;
    top: -0.5rem;
  }
  96% {
    height: 0.84375rem;
    opacity: 0;
    top: 0.5rem;
  }
  100% {
    height: 0;
    opacity: 0;
  }
}
.battery {
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -2rem;
  margin-left: -1.12rem;
  width: 2.25rem;
  height: 4.5rem;
  box-shadow: 0 0 0 0.2rem #425c77;
  background: white;
  border-radius: 0.09rem;
}
.battery:before {
  content: "";
  position: absolute;
  left: 0.5625rem;
  right: 0.5625rem;
  top: -0.3375rem;
  height: 0.3375rem;
  width: 1.125rem;
  background: #425c77;
  border-radius: 0.18rem;
}
.battery:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-right: 2.25rem solid transparent;
  border-bottom: 4.05rem solid rgba(255, 255, 255, 0.325);
}
.liquid {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 2.25rem;
  background: #71fb85;
  animation: load 2.25s infinite;
}
.liquid:after,
.liquid:before {
  content: "";
  position: absolute;
  top: -0.5rem;
  height: 1.125rem;
  width: 1.4625rem;
  background: #71fb85;
  border-radius: 50%;
  opacity: 0;
}
.liquid:after {
  right: 0;
  animation: liquid-1 2.25s infinite;
}
.liquid:before {
  left: 0;
  animation: liquid-2 2.25s infinite;
}

@keyframes power {
  0% {
    color: #bcbcbc;
  }
  75% {
    color: #bcbcbc;
    text-shadow: none;
  }
  90% {
    color: #58fa70;
    text-shadow: 0 0 0.8em #71fb85;
  }
  100% {
    color: #bcbcbc;
    text-shadow: none;
  }
}

.async-select-width {
  width: inherit;
}

.station-details .station-img {
  height: 40vh;
  width: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
  padding: 7px;
}
.station-details .back-button {
  position: absolute;
  right: 15px;
  top: 15px;
}
.station-details .back-button:hover {
  background-color: #fff !important;
}
.station-details .back-button-icon {
  left: 11px !important;
  height: 18px !important;
  width: 20px !important;
}
.station-details .sub-title-div {
  padding-left: 20px !important;
  padding-top: 10px;
}
.station-details .sub-title {
  font-size: 24px;
  color: #333333;
  font-weight: 700;
  position: relative;
  padding-bottom: 5px;
  margin-bottom: 10px;
  text-align: left;
}
.station-details .sub-title::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #d6310f;
  display: block;
  width: 65px;
  height: 3px;
}
.station-details .details-title {
  font-size: 18px;
  font-weight: 500;
  padding: 0px;
  font-size: 18px;
  padding-bottom: 5px;
  font-weight: 500;
}
.station-details .details-div {
  text-align: left;
}
.station-details .amenity-img {
  height: 25px;
  width: 25px;
  margin-right: 10px;
}
.station-details .amenity-img:last-of-type {
  border-radius: 0 15px 15px 0;
}
.station-details .charge-station {
  display: flex;
  flex-flow: column;
  border: 1px;
  border-radius: 6px;
  height: auto;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
.station-details .station-title-div {
  height: 35px;
  margin: 6px;
  border-bottom: 1px solid #4f4f4f;
}
.station-details .station-title-div:last-of-type {
  border-bottom: none !important;
}
.station-details .station-name {
  float: left;
  margin-top: 7px;
  font-weight: 500;
  font-size: 18px;
  margin-left: 15px;
  max-width: calc(100% - 135px);
}
.station-details .connector-title-div {
  display: flex;
  float: left;
  font-weight: bold;
  font-size: 20px;
  margin-left: 15px;
}
.station-details .connector-img {
  height: 28px;
  width: 28px;
  margin-right: 10px;
}
.station-details .connector-title {
  display: grid;
  line-height: 15px;
}
.station-details .connector-name {
  font-size: 16px;
  font-weight: 500;
}
.station-details .connector-volt {
  font-size: 14px;
  color: #4f4f4f;
  text-align: left;
  font-weight: 500;
}
.station-details .cost-div {
  display: flex;
  padding-left: 20px !important;
}

.station-details .cost-div span {
  font-size: 16px;
  padding-top: 1px;
  color: #4f4f4f;
  text-align: left;
}

.station-details .react-switch-handle {
  border: 2px solid #2cae1e !important;
  box-shadow: none !important;
}
.station-details .react-switch-bg div {
  width: 75% !important;
  padding-top: 4px;
  font-size: 14px;
}
.station-details .react-switch-bg span {
  font-size: 12px;
}
.station-details .charge-station-div {
  padding: 20px 20px 30px 20px;
}
.station-details .station-status-div {
  float: right;
  margin-top: 3px;
  margin-right: 10px;
}
.station-details .station-status-badge {
  float: left;
  margin-right: 10px;
}
.station-details .charger-img {
  height: 25px;
  width: 25px;
}
.validate-card-message {
  padding-bottom: 20px;
  font-size: 16px;
}
.card-number {
  font-size: 16px !important;
}

@media (max-width: 767px) {
  .station-details .station-img {
    height: 30vh !important;
  }
}

@media (max-width: 575px) {
  .station-details .details-title {
    font-size: 16px !important;
  }
  .station-details .amenity-img {
    height: 20px;
    width: 20px;
  }
  .station-details .connector-title-div {
    margin-left: 0px;
  }
  .station-details .connector-img {
    height: 23px;
    width: 18px;
    margin-right: 10px;
  }
  .station-details .connector-name {
    font-size: 14px;
  }
  .station-details .connector-volt {
    font-size: 13px;
  }
  .station-details .cost-div span {
    font-size: 14px !important;
  }
}

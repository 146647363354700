.payment-fail .error-color {
  color: #d73a1f;
}
.payment-fail .error-message {
  font-size: 19px;
  line-height: 20px;
}
.payment-success .info-text {
  font-size: 22px;
  color: #d73a1f;
}
.payment-success .amount-span {
  padding-right: 5px;
}
.payment-success .error-color {
  color: #d73a1f;
}
.payment-success .error-message {
  font-size: 19px;
  line-height: 20px;
}

@media (max-width: 575px) {
  .payment-success .info-text {
    font-size: 16px;
  }
}
